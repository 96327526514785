body {
  margin: 16px;
  font-family: "Roboto", sans-serif;
  background-color: var(--primary-background-color);
  color:var(--primary-text-color);
}

.centered {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-medium);
}

table {
  border-collapse: collapse;
  width:100%;
  margin-bottom: var(--spacing-medium);
}

table td {
	padding: 15px;
}

table thead td {
	background-color: var(--inverted-color-background);
	color: var(--text-color-on-inverted);
	font-weight: bold;
	font-size: 13px;
	border: 1px solid var(--layout-border-color);
}

table thead td.danger {
  background-color: var(--negative-color);
}

table tbody td {
	color:var(--primary-text-color);
	border: 1px solid var(--grey-background-color);
}

table tbody tr {  
  background-color: var(--allgrey-background-color);
}

table tbody tr:nth-child(odd) {
	background-color: var(--primary-background-color);
}